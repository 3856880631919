import { template as template_293d8bdf070a4f6c864b055d0fb3b15d } from "@ember/template-compiler";
const SidebarSectionMessage = template_293d8bdf070a4f6c864b055d0fb3b15d(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
